import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { AuthGuardController as AuthGuard } from './controllers/auth-guard.controller'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'employees',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
  },
  {
    path: 'change-password/:token',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(m => m.ChangePasswordPageModule),
  },
  {
    path: 'overview',
    loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewPageModule),
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'matching',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/matching/matching.module').then(m => m.MatchingPageModule),
      },
      {
        path: 'create/:orderId',
        loadChildren: () =>
          import('./pages/create-matching/create-matching.module').then(m => m.CreateMatchingPageModule),
      },
      {
        path: 'create/:orderId/:assignmentId',
        loadChildren: () =>
          import('./pages/create-matching/create-matching.module').then(m => m.CreateMatchingPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'orders',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
      },
      {
        path: 'create/:id',
        loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule),
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'employees',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/employees/employees.module').then(m => m.EmployeesPageModule),
      },
      {
        path: 'create',
        loadChildren: () =>
          import('./pages/create-employee/create-employee.page.module').then(m => m.CreateEmployeePageModule),
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/employee-view/employee-view.page.module').then(m => m.EmployeeViewPageModule),
      },
      {
        path: 'edit/:id',
        loadChildren: () =>
          import('./pages/create-employee/create-employee.page.module').then(m => m.CreateEmployeePageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'supervisor',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/supervisor/supervisor.module').then(m => m.SupervisorPageModule),
      },
      {
        path: 'create',
        loadChildren: () =>
          import('./pages/create-supervisor/create-supervisor.page.module').then(
            m => m.CreateSupervisorPageModule
          ),
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/supervisor-view/supervisor-view.module').then(m => m.SupervisorViewPageModule),
      },
      {
        path: 'edit/:id',
        loadChildren: () =>
          import('./pages/create-supervisor/create-supervisor.page.module').then(
            m => m.CreateSupervisorPageModule
          ),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'clients',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsPageModule),
      },
      {
        path: 'create',
        loadChildren: () =>
          import('./pages/create-client/create-client.page.module').then(m => m.CreateClientPageModule),
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/client/client.module').then(m => m.ClientPageModule),
      },
      {
        path: 'edit/:id',
        loadChildren: () =>
          import('./pages/create-client/create-client.page.module').then(m => m.CreateClientPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'clients-dashboard',
    loadChildren: () =>
      import('./pages/clients-dashboard/clients-dashboard.module').then(m => m.ClientsDashboardModule),
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'messages',
    children: [
      { path: '', redirectTo: '/messages/general', pathMatch: 'full' },
      {
        path: 'general',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule),
      },
      {
        path: 'assignation',
        loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule),
      },
      {
        path: 'create',
        loadChildren: () =>
          import('./pages/create-message/create-message.module').then(m => m.CreateMessagePageModule),
      },
      {
        path: 'edit/:id',
        loadChildren: () =>
          import('./pages/create-message/create-message.module').then(m => m.CreateMessagePageModule),
      },
      {
        path: ':id',
        loadChildren: () =>
          import('./pages/conversation/conversation.module').then(m => m.ConversationPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'skills',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/skills/skills.page.module').then(m => m.SkillsPageModule),
      },
      {
        path: 'create',
        loadChildren: () =>
          import('./pages/create-skill/create-skill.page.module').then(m => m.CreateSkillPageModule),
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/skill-view/skill-view.page.module').then(m => m.SkillViewModule),
      },
      {
        path: 'edit/:id',
        loadChildren: () =>
          import('./pages/create-skill/create-skill.page.module').then(m => m.CreateSkillPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'tools',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/tools/tools.module').then(m => m.ToolsPageModule),
      },
      {
        path: 'create',
        loadChildren: () =>
          import('./pages/create-tool/create-tool.page.module').then(m => m.CreateToolPageModule),
      },
      {
        path: ':id',
        loadChildren: () => import('./pages/tool-view/tool-view.page.module').then(m => m.ToolViewPageModule),
      },
      {
        path: 'edit/:id',
        loadChildren: () =>
          import('./pages/create-tool/create-tool.page.module').then(m => m.CreateToolPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'timesheets',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/timesheets/timesheets.module').then(m => m.TimeSheetsPageModule),
        canActivate: [AuthGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'create',
        loadChildren: () =>
          import('./pages/create-timesheet/create-timesheet.module').then(m => m.CreateTimesheetPageModule),
        canActivate: [AuthGuard],
        data: { roles: ['admin'] },
      },
      {
        path: 'edit/:id',
        loadChildren: () =>
          import('./pages/create-timesheet/create-timesheet.module').then(m => m.CreateTimesheetPageModule),
        canActivate: [AuthGuard],
        data: { roles: ['admin', 'supervisor'] },
      },
    ],
  },
  {
    path: 'dashboard-supervisor',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/dashboard-supervisor/dashboard-supervisor.module').then(
            m => m.DashboardSupervisorPageModule
          ),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['supervisor'] },
  },
  {
    path: 'dashboard',
    children: [
      {
        path: 'map',
        loadChildren: () =>
          import('./pages/dashboard-map/dashboard-map.module').then(m => m.DashboardMapPageModule),
      },
      {
        path: 'map/:id',
        loadChildren: () =>
          import('./pages/dashboard-map/dashboard-map.module').then(m => m.DashboardMapPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'time-entries',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/time-entries/time-entries.module').then(m => m.TimeEntriesPageModule),
      },
      {
        path: ':employeeId',
        loadChildren: () =>
          import('./pages/time-entries/time-entries.module').then(m => m.TimeEntriesPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin', 'employee', 'supervisor'] },
  },
  {
    path: 'time-logger',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/time-logger/time-logger.module').then(m => m.TimeLoggerPageModule),
      },
      {
        path: ':timeEntryId',
        loadChildren: () =>
          import('./pages/time-logger/time-logger.module').then(m => m.TimeLoggerPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin', 'employee', 'supervisor'] },
  },
  {
    path: 'absences',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/absences/absences.module').then(m => m.AbsencesPageModule),
      },
      {
        path: 'create',
        loadChildren: () =>
          import('./pages/create-tool/create-tool.page.module').then(m => m.CreateToolPageModule),
      },
      {
        path: 'edit/:id',
        loadChildren: () =>
          import('./pages/create-tool/create-tool.page.module').then(m => m.CreateToolPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'alerts',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/alerts/alerts.module').then(m => m.AlertsPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'hour-alerts',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/hour-alerts/hour-alerts.module').then(m => m.HourAlertsPageModule),
      },
    ],
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'dashboard-employees',
    loadChildren: () =>
      import('./pages/dashboard-employees/dashboard-employees.module').then(m => m.EmployeesDashboardModule),
    canActivate: [AuthGuard],
    data: { roles: ['admin'] },
  },
  { path: '**', redirectTo: '/' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
