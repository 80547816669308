import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { DateTime } from 'luxon'
import { INITIAL_HOUR_ALERTS_DATE } from 'src/app/utils/constants.utils'
import { HeaderService } from '../services/header.service'
import { AuthProvider } from './auth.provider'
import { serverAddresses } from './server.provider'

@Injectable({
  providedIn: 'root',
})
export class AlertProvider {
  server = serverAddresses.api

  constructor(
    public http: HttpClient,
    public authService: AuthProvider,
    private headerService: HeaderService
  ) {}

  getAlerts(days: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let url = this.server + '/alerts?days=' + days

      this.http.get(url, { headers: this.headerService.getCommonHeaders() }).subscribe(
        (res: any[]) => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    })
  }

  getAlertsNotifications(days: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let url = this.server + '/alerts/notifications?days=' + days

      this.http.get(url, { headers: this.headerService.getCommonHeaders() }).subscribe(
        (res: any[]) => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    })
  }

  get getDefaultHoursWorkedDate(): string {
    return DateTime.fromJSDate(INITIAL_HOUR_ALERTS_DATE).toFormat('dd/MM/yyyy')
  }

  getHoursWorked(
    startDate: string = this.getDefaultHoursWorkedDate,
    endDate: string = null
  ): Promise<HoursWorkedEntry[]> {
    return new Promise((resolve, reject) => {
      let url = `${this.server}/time-entries/total-hours-worked-by-date?startDate=${startDate}`
      if (endDate) {
        url += `&endDate=${endDate}`
      }

      this.http.get(url, { headers: this.headerService.getCommonHeaders() }).subscribe(
        (res: HoursWorkedEntry[]) => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    })
  }

  getHoursWorkedByEmployee(
    startDate: string = null,
    endDate: string = null,
    employeeId: string = null
  ): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let url = `${this.server}/time-entries/total-hours-worked-by-date-and-by-employee`

      let options = []

      if (startDate != null) {
        options.push(`startDate=${startDate}`)
      }
      if (endDate != null) {
        options.push(`endDate=${endDate}`)
      }
      if (employeeId != null) {
        options.push(`employeeId=${employeeId}`)
      }

      if (options.length > 0) {
        url += '?' + options.join('&')
      }

      this.http.get(url, { headers: this.headerService.getCommonHeaders() }).subscribe(
        (res: any[]) => {
          resolve(res)
        },
        err => {
          reject(err)
        }
      )
    })
  }
}

export interface HoursWorkedEntry {
  timeEntryId: string
  assignmentId: string
  employeeId: string
  firstName: string
  lastName: string
  totalHours: number
  sites: {
    workedHours: number
    name: string
    clientName: string
  }[]
  adminComments: string
}
